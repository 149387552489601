<template>
  <title>Cee Kay Admin</title>
  <ck-header />
  <router-view />
</template>

<script setup>
import { onMounted } from "vue";
import ckHeader from "./components/ckHeader.vue";
import axios from "axios";
import { logIn } from "./store";
import "@fortawesome/fontawesome-free/css/all.css";
import "bulma/css/bulma.css";
import "@cityssm/bulma-sticky-table/bulma-with-sticky-table.css";

onMounted(async () => {
  try {
    const response = await axios.post("api/authentication/checkLogin");
    console.log(response.data);
    logIn(response.data);
  } catch (err) {
    //Means we aren't logged in so dw about it
  }
});
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

/* Globally removing the up/down buttons from number input boxes */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
