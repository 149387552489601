import { reactive, readonly, DeepReadonly } from "vue";

interface StoreState {
  username: string;
  isLoggedIn: boolean;
}

// Make the state object reactive
const state = reactive<StoreState>({
  username: "NULL",
  isLoggedIn: false,
});

// Export the state object as read-only to prevent accidental modifications
export const store: DeepReadonly<StoreState> = readonly(state);

export function logIn(login_response: { username: string }): void {
  state.username = login_response.username;
  state.isLoggedIn = true;
}
