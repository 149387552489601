import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/analysis",
    name: "analysis",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "analysis" */ "../views/ReorderView.vue"),
  },
  {
    path: "/purchase_order",
    name: "purchase_order",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "purchase_order" */ "../views/PurchaseOrderView.vue"
      ),
  },
  {
    path: "/purchase_order_history",
    name: "purchase_order_history",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "purchase_order_history" */ "../views/PurchaseOrderHistoricalView.vue"
      ),
  },
  {
    path: "/accounts",
    name: "accounts",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "accounts" */ "../views/AccountsView.vue"),
  },
  {
    path: "/vendor_settings",
    name: "vendor_settings",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "vendor_settings" */ "../views/VendorSettingsView.vue"
      ),
  },
  {
    path: "/discontinued",
    name: "discontinued",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "discontinued" */ "../views/DiscontinuedView.vue"
      ),
  },
  {
    path: "/lowsale",
    name: "lowsale",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "low_sales" */ "../views/LowSalesView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
